export const findLongestWord = str => {
    const strSplit = str.split(' ');
    const strSplitLenght = strSplit.length;

    let longestWord = 0;
    let currentWord = 0;

    for (let i = 0; i < strSplitLenght; i++) {
        currentWord = strSplit[i].length;
        if (currentWord > longestWord) {
            longestWord = currentWord;
        }
    }
    return longestWord;
};

export default findLongestWord;
