import React from 'react';
import PropTypes from 'prop-types';
import Content from 'theme/components/productguide/Content';
import AspectWrapper from 'core-web/components/AspectWrapper';
import { useTranslation } from 'react-i18next';

const ProductGuide = ({ loading, data, isInProductGrid, isWide, isHero, isH1Heading }) => {
    const { t } = useTranslation();
    const mobileRatio = isHero ? 418 / 418 : isWide ? 418 / 360 : 205 / 360;
    const tabletRatio = isWide ? 418 / 360 : 205 / 360;
    const desktopRatio = isWide ? 696 / 460 : 340 / 460;

    return (
        <AspectWrapper ratio={[mobileRatio, tabletRatio, desktopRatio]} position="relative">
            <Content
                withoutImage
                isInProductGrid={isInProductGrid}
                data={data}
                t={t}
                loading={loading}
                isWide={isWide}
                isH1Heading={isH1Heading}
            />
        </AspectWrapper>
    );
};

ProductGuide.propTypes = {
    data: PropTypes.object.isRequired,
    isH1Heading: PropTypes.bool,
    isHero: PropTypes.bool,
    isInProductGrid: PropTypes.bool,
    isWide: PropTypes.bool,
    loading: PropTypes.string,
};

ProductGuide.defaultProps = {
    isH1Heading: false,
    isHero: false,
    isInProductGrid: false,
    isWide: false,
    loading: 'lazy',
};

export default ProductGuide;
