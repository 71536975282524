import React from 'react';
import PropTypes from 'prop-types';
import findLongestWord from 'core-web/util/findLongestWord';
import { above } from 'core-web/util/mediaqueries';
import AspectWrapper from 'core-web/components/AspectWrapper';
import BackgroundImage from 'core-web/components/BackgroundImage';
import Above from 'core-web/components/Breakpoints/Above';
import Below from 'core-web/components/Breakpoints/Below';
import Link from 'core-web/components/Link';
import colors from 'theme/config/colors';
import styled from 'theme/libs/styled';
import H1 from 'theme/components/base/headings/H1';
import H2 from 'theme/components/base/headings/H2';
import H4 from 'theme/components/base/headings/H4';
import RoundedButton from 'theme/components/buttons/RoundedButton';
import BigArrowIcon from 'theme/components/icons/BigArrowIcon';

const ContentLink = styled('div')`
    display: flex;
    align-items: center;
    height: 100%;

    & > div .arrow {
        transition: transform 0.25s ease;
    }

    ${above.lg} {
        &:hover > div .arrow {
            transform: translateX(4px);
        }
    }
`;

const Wrapper = styled('div')`
    display: block;
    padding: 8px;
    text-align: center;
    text-decoration: none;
    margin: auto;

    ${above.lg} {
        padding: 16px;
    }

    &[data-textshadow='true'] {
        h1,
        h2,
        h4,
        p {
            text-shadow: 2px 3px 5px black;
        }
    }
`;

const ArrowWrapper = styled('div')`
    position: absolute;
    bottom: 0;
    right: 0;
    margin: 0 8px 8px 0;

    ${above.lg} {
        margin: 0 12px 12px 0;
    }
`;

const Video = styled('video')`
    object-fit: cover;
    width: 100%;
    height: 100%;
    position: absolute;
    top: 0;
`;

const Content = ({ data, withoutImage, isH1Heading, isInProductGrid, handleTracking }) => {
    const { elements, background_color: backgroundColor, text_shadow: textShadow } = data;
    const color = backgroundColor && colors[backgroundColor.color_selection];
    const themeColor = backgroundColor && backgroundColor.color_selection === 'green' ? 'black' : 'green';

    const heading = elements && elements.includes('heading') && data.heading;
    const preheader = elements && elements.includes('pre_header') && data.pre_header;
    const link = elements && elements.includes('link') && data.link;
    const { text: linkText, url: linkUrl } = link || {};

    const desktopVideo = elements && elements.includes('video') && data.video.desktop_video.url;
    const mobileVideo = elements && elements.includes('video') && data.video.mobile_video.url;

    const { loop, muted, poster, autoplay, controls } =
        elements && elements.includes('video') && data.video.settings ? data.video.settings : {};

    let setSizes = {};
    if (heading) {
        const longest = findLongestWord(heading);
        const large = longest >= 12;
        const medium = longest >= 8 && longest < 12;
        const small = longest <= 7;
        setSizes = {
            fontSize: large ? '20px' : medium ? '24px' : small ? '32px' : '20px',
            lineHeight: large ? '28px' : medium ? '32px' : small ? '40px' : '28px',
        };
    }

    return (
        <ContentLink
            as={!linkUrl ? 'div' : Link}
            to={linkUrl ? linkUrl : null}
            backgroundColor={withoutImage && color}
            onClick={() => handleTracking(heading)}
        >
            <Wrapper data-textshadow={textShadow}>
                {preheader && (
                    <H4 as={isInProductGrid ? 'p' : H4} mb="8px" color={colors.white} fontFamily="Acumin" zIndex="10">
                        {preheader}
                    </H4>
                )}
                {heading ? (
                    isH1Heading ? (
                        <H1
                            as={isInProductGrid ? 'p' : H1}
                            fontWeight="700"
                            color={colors.white}
                            lineHeight="40px"
                            zIndex="10"
                        >
                            {heading}
                        </H1>
                    ) : (
                        <H2
                            as={isInProductGrid ? 'p' : H2}
                            fontWeight="700"
                            color={colors.white}
                            lineHeight={[setSizes.lineHeight, null, '40px']}
                            fontSize={[setSizes.fontSize, null, '32px']}
                            zIndex="10"
                        >
                            {heading}
                        </H2>
                    )
                ) : null}
                {linkUrl && linkText && (
                    <RoundedButton
                        as="div"
                        className="button"
                        theme={themeColor}
                        margin="24px auto 0"
                        fontSize={['12px', null, '14px']}
                        width="fit-content"
                        height="fit-content"
                        padding="16px"
                        zIndex="10"
                    >
                        {link.text}
                    </RoundedButton>
                )}
                {linkUrl && !linkText && (
                    <ArrowWrapper className="arrow" zIndex="10">
                        <BigArrowIcon width="31px" height="24px" color={colors.white} direction="right" />
                    </ArrowWrapper>
                )}
                {mobileVideo && desktopVideo && (
                    <Video
                        playsInline
                        autoPlay={autoplay}
                        controls={controls}
                        loop={loop}
                        muted={muted}
                        poster={poster}
                    >
                        <Below breakpoint="lg" render={() => <source src={mobileVideo} type="video/mp4" />} />
                        <Above breakpoint="lg" render={() => <source src={desktopVideo} type="video/mp4" />} />
                    </Video>
                )}
            </Wrapper>
        </ContentLink>
    );
};

Content.propTypes = {
    data: PropTypes.object.isRequired,
    handleTracking: PropTypes.func,
    isH1Heading: PropTypes.bool.isRequired,
    isInProductGrid: PropTypes.bool.isRequired,
    withoutImage: PropTypes.bool,
};

Content.defaultProps = {
    handleTracking: () => null,
    withoutImage: false,
};

const CampaignCard = ({ auto, loading, data, isWide, isHero, isH1Heading, isInProductGrid, handleTracking }) => {
    const { elements, image, background_color: backgroundColor } = data;
    const { image_size: imageSize, image_position: imagePosition } = image || {};

    const mobileImage = elements.includes('image') && (image.mobile_image || {}).url;
    const desktopImage = elements.includes('image') && (image.desktop_image || {}).url;

    const color = backgroundColor && colors[backgroundColor.color_selection];

    const mobileRatio = isHero ? 418 / 418 : isWide ? 418 / 360 : 205 / 360;
    const tabletRatio = isWide ? 418 / 360 : 205 / 360;
    const desktopRatio = isWide ? 696 / 460 : 340 / 460;

    return (
        <AspectWrapper height="100%" ratio={[mobileRatio, tabletRatio, desktopRatio]} position="relative">
            {mobileImage || desktopImage ? (
                <BackgroundImage
                    src={[mobileImage || desktopImage, null, desktopImage || mobileImage]}
                    query={{
                        auto,
                        w: isWide ? [418, null, 696] : [205, null, 340],
                        bg: color,
                    }}
                    backgroundColor={color}
                    backgroundPosition={imagePosition}
                    backgroundSize={imageSize}
                    loading={loading}
                >
                    <Content
                        data={data}
                        isH1Heading={isH1Heading}
                        isWide={isWide}
                        isInProductGrid={isInProductGrid}
                        handleTracking={handleTracking}
                    />
                </BackgroundImage>
            ) : (
                <Content
                    withoutImage
                    data={data}
                    isH1Heading={isH1Heading}
                    isWide={isWide}
                    color={color}
                    isInProductGrid={isInProductGrid}
                    handleTracking={handleTracking}
                />
            )}
        </AspectWrapper>
    );
};

CampaignCard.propTypes = {
    auto: PropTypes.string,
    data: PropTypes.object.isRequired,
    handleTracking: PropTypes.func,
    isH1Heading: PropTypes.bool,
    isHero: PropTypes.bool,
    isInProductGrid: PropTypes.bool,
    isWide: PropTypes.bool,
    loading: PropTypes.string,
};

CampaignCard.defaultProps = {
    auto: null,
    handleTracking: () => null,
    isH1Heading: false,
    isHero: false,
    isInProductGrid: false,
    isWide: false,
    loading: 'lazy',
};

export default CampaignCard;
